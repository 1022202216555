<section class="py-2 contact-us">
  <div class="container">
      <div class="row my-4 d-flex justify-content-center align-items-center">

        <div class="col-6 col-xl-3 col-md-6 col-sm-6 d-flex justify-content-xl-center justify-content-center align-items-center mb-xl-0">
              <img src="../assets/logo_mined-1.png" class="logo" >
        </div>

        <div class="col-6 col-xl-3 col-md-6 col-sm-6 d-none d-sm-block order-xl-3 d-flex justify-content-xl-center justify-content-center align-items-center mb-xl-0 text-right">
            <img src="../assets/secretaria.png" class="logo" style="max-width: 95%;" alt="">
        </div>

          <div class="col-12 col-xl-6 col-sm-12 d-flex flex-column justify-content-start align-items-center find-us ">
            <h4 class="text-cyan mb-3">Encuéntranos</h4>
            <div>
                <a href="#" class="rounded-circle social-media" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/ig-logo.svg" alt="">
                </a>
                <a href="https://www.facebook.com/minedsv/" class="rounded-circle social-media" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/fb-logo.svg" alt="MINED Facebook page link">
                </a>
                <a href="https://twitter.com/EducacionSV" class="rounded-circle social-media" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/tw-logo.svg" alt="MINED Twitter profile link">
                </a>
                <a href="https://www.youtube.com/channel/UCbd7dsgHEBxDrHXPeGK9FRQ" class="rounded-circle social-media" target="_blank" rel="noopener noreferrer">
                    <img src="../assets/yt-logo.svg" alt="Escuela Abierta YouTube profile link">
                </a>
            </div>
        </div>
      </div>
      <div class="row my-4 d-flex justify-content-center align-items-start">
        <div class="col-12">
          <div class="mb-0 text-center text-cream font-weight-bold">
            <a class="link rounded-pill" href="/politicasprivacidad">Términos y Condiciones</a>
          </div>
        </div>
      </div>
  </div>
</section>

<footer class="text-center text-dark-blue py-3">
  <p class="m-0">
    <span class="font-weight-bold">
      Copyright &copy; 2020 | Escuela Abierta para la Convivencia
    </span>
    | Ministerio de Educación, Ciencia y Tecnología
    | Secretaría de Innovación</p>
</footer>
