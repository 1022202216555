<header>

  <div class="row py-2">

    <div class="col-12 col-xl-12 d-flex justify-content-center justify-content-xl-center align-items-center">
      <div class="col-8 d-flex justify-content-around">
        <a href="#"> <img class="img-fluid logoeac mx-md-1 mx-lg-2" src="../assets/eac.png" ></a>
          <a href="#"> <img class="img-fluid logomin mx-md-1 mx-lg-2" src="../assets/logo_secretaria-2.png"></a>
          <a href="#"> <img class="img-fluid logomin mx-md-1 mx-lg-2" src="../assets/logo_mined-3.png"></a>
      </div>
    </div>

  </div>

</header>

